import axios from "axios";
import validation from "../../helpers/validation";
import state from '@/store/state.js'

//add asynchronous call to twilio client on leads pipeline
async function validPhone(val) {
  if (!!val && val.length < 1) { return false }
      
  try {
    let config = {
      method: 'post',
      maxBodyLength: 500,
      url: state.phoneValidationUrl,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : JSON.stringify({"phone": val, "account": state.submit.accountId})
    };
  
    let response = await axios(config);
    if(response.status == 200 || response.status >= 500) {
      return true;
    }
    
  } catch(err) {
      if(err.response) {
        validation.setValidationMessage('phone', err.response.data.message)
        return false;
      }
      //default fallback
      return false
  }
} //end func

export default validPhone