import axios from 'axios'
import helpers from '@/helpers/helpers'
import validation from '@/validation'

export default {
  /**
   * Initializes the Freya Form Renderer plugin. 
   * 
   * @param {Object} context
   * @param {Object} context.getters
   * @param {Object} context.dispatch
   * @param {Object} payload
   * @param {Object} payload.pageContext
   * 
   * @returns {Void}
   */
  initializePlugin: ({ getters, dispatch }, payload) => {
    if (! ('formConfig' in payload)) {
      console.error('Freya Form Renderer: A form configuration was not found. Please specify a form configuration to properly initialize the plugin.')
    } else {
      dispatch('setPageContext', payload.pageContext ??  'default')
      dispatch('setFormConfig', payload.formConfig)
      
      if ('groups' in payload && payload.groups) {
        dispatch('setProgramGroups', payload.groups)
      }
      
      dispatch('setAvailablePrograms', {groups: payload.groups})

      dispatch('updateSubmitObject', {
        key: 'formId',
        value: payload.formConfig.id
      })

    }

    /**
     * Set the partner name, if applicable.
     */
    if ('partnerName' in payload) {
      dispatch('setPartnerName', payload.partnerName)
    }

     /**
     * Set the embed id, if applicable.
     */
      if ('embedId' in payload) {
        dispatch('setEmbedId', payload.embedId)
      }

    /**
     * Set the account that the form is associated with, if applicable.
     */
    if ('accountId' in payload) {
      dispatch('updateSubmitObject', {
        key: 'accountId',
        value: payload.accountId
      })
    }

    /**
     * Set the variant that the renderer is to display, if applicable. If not, set to default
     */
     if ('pageContext' in payload) {
      dispatch('updateSubmitObject', {
        key: 'pageContext',
        value: payload.pageContext
      })
    } else if (!('pageContext' in payload)) {
      dispatch('updateSubmitObject', {
        key: 'pageContext',
        value: 'default'
      })
    }   

    /**
     * Set whether or not the page is a landing page.
     */
      dispatch('updateSubmitObject', {
        key: 'isLandingPage',
        value: window.isLandingPage ?? payload.isLandingPage ?? false
      })

    /**
     * Set whether or not the page is an affiliate page, if applicable.
     */
    if ('isAffiliate' in payload) {
      dispatch('updateSubmitObject', {
        key: 'affiliate',
        value: payload.isAffiliate ?? false
      })
    }

    /**
     * Specify whether or not the form should be showcased as a single program form.
     * 
     * In order to do this, we need to make sure that we have a default program id
     * alongside the single program toggle. In the event we do not have a default
     * program id, we do NOT want to hide the programs field. In this scenario we
     * would want to showcase the programs field as a fallback for stability. 
     */
    if ('isSingleProgram' in payload && 'defaultProgramId' in payload) {
      if (payload.isSingleProgram && payload.defaultProgramId) {
        dispatch('setHiddenFields', [
          'fr-programs'
        ])
      }
    }

    /**
     * Set the default program id on the submit object
     */
    if ('defaultProgramId' in payload && payload.defaultProgramId) {
      /** 
       * check and see if the default program is in the programs list first 
       */
      let programsList = !!getters.getAvailablePrograms ? getters.getAvailablePrograms : [] //if it exists, get the programs - else set to empty

      if(!!programsList.find(program => program.id == payload.defaultProgramId)) { //if the find returns a truthy value
        dispatch('updateSubmitObject', {
          key: 'program',
          value: payload.defaultProgramId
        })
      }

      let program = getters.getSelectedProgram

      if ('isAffiliate' in payload && payload.isAffiliate) {
        dispatch('updateSubmitObject', {
          key: 'leadBuyerID',
          value: program.account.data.lead_buyer_id ?? null
        })
      }
    }
     
    let program = getters.getSelectedProgram

    dispatch('updateSubmitObject', {
      key: 'partnerCode',
      value: program?.account?.data?.internal_partner_code ?? null
    })

    dispatch('updateSubmitObject', {
      key: 'postingRoute',
      value: program?.account?.data?.posting_route ?? null
    })

    /**
     * Sets the submission URL for the form, if applicable.
     */
    if ('submissionUrl' in payload) {
      dispatch('setSubmissionUrl', payload.submissionUrl)
    }

    /**
     * Set the form source.
     */
    dispatch('updateSubmitObject', {
      key: 'formSource',
      value: window.location.href
    })

    /**
     * Set the order ID.
     */
    dispatch('updateSubmitObject', {
      key: 'orderId',
      value: helpers.generateOrderId()
    })

    /**
     * Set the partner website external ID (pweid).
     */
    dispatch('updateSubmitObject', {
      key: 'pweid',
      value: helpers.generatePartnerWebsiteExternalId()
    })
 
  },

    /**
   * Sets the analytics values for tracking purposes.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  setAnalytics: ({ commit }) => {
    if (window.dataLayer) {

      commit('updateSubmitObject', {
        key: 'gaUaId',
        value: helpers.getMeasurementId()
      })

      commit('updateSubmitObject', {
        key: 'gaClientId',
        value: helpers.getClientId()
      })
    }
    
    if (window.clientIdIndex) {
      commit('updateSubmitObject', {
        key: 'clientIdIndex',
        value: window.clientIdIndex
      })
    }
  },

  /**
   * Sets the initial values for the form fields.
   * 
   * @param {Object} context 
   * @param {Object} context.getters
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  setDefaultFieldValues: ({ getters, commit }) => {
    let formSteps = getters.getFormSteps
    //check if sms consent checkbox exists
    //if it doesn't, set okToText to true
    formSteps.forEach(step => {
      let hasSms = step.find(field => field.id === 'sms-consent-checkbox')
      if (typeof hasSms == 'undefined') {
        commit('updateSubmitObject', {
          key: 'okToText',
          value: true
        })
      }
      step.forEach(fieldObject => {
        if ('submitKey' in fieldObject.meta && fieldObject.meta.submitKey) {
          commit('updateSubmitObject', {
            key: fieldObject.meta.submitKey,
            value: fieldObject.meta.default ?? ''
          })
        }
      })
    })
  },

  /**
   * Initialize the form variant to be used on the form.
   * 
   * @param {Object} context 
   * 
   * @returns {Void}
   */

  setInitialFormVariant: ({ getters, commit}, payload) => {
    let pageContext = getters.getPageContext
    let variantTypes = getters.getVariantTypes
    
    commit('setFormVariant', payload.find(v => v.variantType == variantTypes[pageContext]))
  },

  /**
   * Sets the partner's name.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setPartnerName: ({ commit }, payload) => {
    commit('setPartnerName', payload)
  },

  /**
   * Sets the embed id.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setEmbedId: ({ commit }, payload) => {
    commit('setEmbedId', payload)
  },

  /**
   * Sets the page context.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
   setPageContext: ({ commit }, payload) => {
    commit('setPageContext', payload)
  },  

  /**
   * Sets the program groups on the field.
   * 
   * @param {Object} context 
   * @param {String} payload
   * 
   * @returns {Void}
   */
   setProgramGroups: ({ getters }, payload) => {
    let formSteps = getters.getFormSteps

    formSteps.forEach(step => {
      step.forEach(fieldObject => {
        if (fieldObject.id == 'programs' && payload) {
          fieldObject.meta.groups = payload
        }
      })
    })
  },    
    
  /**
   * Sets the form config to the given value.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {Object} context.dispatch
   * @param {Object} context.getters
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFormConfig: ({ commit, dispatch }, payload) => {
    commit('setFormConfig', payload)
    
    dispatch('setInitialFormVariant',payload.variants)
    dispatch('setDefaultFieldValues')
    
  },

  /**
   * Increments the current form step.
   * 
   * @param {Object} context 
   * @param {Object} context.getters
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  incrementFormStep: ({ getters, commit }) => {
    commit(
      'setCurrentFormStep', 
      getters.getCurrentFormStep + 1
    )
  },

  /**
   * Decrements the current form step.
   * 
   * @param {Object} context 
   * @param {Object} context.getters
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  decrementFormStep: ({ getters, commit }) => {
    commit(
      'setCurrentFormStep', 
      getters.getCurrentFormStep - 1
    )
  },

  /**
   * Updates the submit object.
   * 
   * @param {Object} context
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  updateSubmitObject: ({ commit }, payload) => {
    commit('updateSubmitObject', payload)
  },

  /**
   * Sets the submission URL for the form.
   * 
   * @param {Object} context
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setSubmissionUrl: ({ commit }, payload) => {
    commit('setSubmissionUrl', payload)
  },

  /**
   * Sets the email URL for the form.
   * 
   * @param {Object} context
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setEmailValidationUrl: ({ commit }, payload) => {
    commit('setEmailValidationUrl', payload)
  },

  /**
   * Sets the phone URL for the form.
   * 
   * @param {Object} context
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setPhoneValidationUrl: ({ commit }, payload) => {
    commit('setPhoneValidationUrl', payload)
  },

  /**
   * Sets the zip URL for the form.
   * 
   * @param {Object} context
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setZipValidationUrl: ({ commit }, payload) => {
    commit('setZipValidationUrl', payload)
  },

  /**
   * Sets the programs filter.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setProgramsFilter: ({ commit }, payload) => {
    commit('setProgramsFilter', payload)
  },

  /**
   * Sets the fields that should not be rendered.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setHiddenFields: ({ commit }, payload) => {
    commit('setHiddenFields', payload)
  },

  /**
   * Sets the last phone entry that passed validation.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setLastPhone: ({ commit }, payload) => {
    commit('setLastPhone', payload)
  },

  /**
   * Sets the last email entry that passed validation.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setLastEmail: ({ commit }, payload) => {
    commit('setLastEmail', payload)
  },
  
  /**
   * Sets the last zip entry that passed validation.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setLastZip: ({ commit }, payload) => {
    commit('setLastZip', payload)
  },

  /**
   * Submits the form to the submission URL specified.
   * 
   * @param {Object} context
   * @param {Object} context.getters
   * @param {Object} context.dispatch
   * 
   * @returns {Void}
   */
  submitForm: ({ getters, commit, dispatch }) => {
    commit('setSubmittingState', true);

    let queryParameters = ''
    let referrer = ''
    let qry_string = ''

    dispatch('setAnalytics')
 
    if(helpers.getCookie('rfi_query_string')) {
      queryParameters = helpers.parseQueryParameters(helpers.getCookie('rfi_query_string'))
    }
    else {
      let rfi_params = window.location.search.substr(1).replace(/&&/igm, '&'),
        pantheon_stripped = rfi_params.toUpperCase().indexOf("PANTHEON_STRIPPED")
 
      //Return null if contains pantheon stripped
      if (pantheon_stripped < 0) {
          queryParameters = helpers.parseQueryParameters(rfi_params)
      }
    }   

    for (let key in queryParameters) {
       commit('updateSubmitObject', {
         key: key,
         value: queryParameters[key]
       })
     }

    if (window.formAnalytics) {
      referrer = window.formAnalytics.referrer
      qry_string = window.formAnalytics.qry_string
    } else {
      referrer = document.referrer
      qry_string = window.location.search
    }

    commit('updateSubmitObject', {
      key: 'referrer',
      value: referrer
    }),

    commit('updateSubmitObject', {
      key: 'qryString',
      value: qry_string
    })

    commit('updateSubmitObject', {
      key: 'accountId',
      value: getters.getSelectedProgram.account_id
    })
		
    commit('updateSubmitObject', {
      key: 'entryId',
      value: window.entryId
    })

    let submissionData = {
      ...getters.getSubmitObject,
      programCode: getters.getSelectedProgram.program_code ?? null
    }

    // Check the program qualifier to see if we need a substitution.
    // We only want to substitute the program if the qualifier 'fails'.
    let qualifier = getters.getSelectedProgramQualifier
    let redirectUrl = getters.getRedirectUrl

    if (
      'substitution' in qualifier && 
      qualifier.substitution && 
      submissionData.programQualifier === false
    ) {
      submissionData.program = qualifier.program_uuid
      redirectUrl = qualifier.redirect_url
    }

    let meta_cookie = helpers.readCookie('_fbc') ?? ''
    submissionData.fbcid = meta_cookie
            
    axios.post(
      getters.getSubmissionUrl, 
      submissionData
    ).then(() => {
      commit('setSubmittingState', false)
      helpers.redirectAfterSubmission(
        redirectUrl, 
        submissionData
      )
    }).catch(err => {
      commit('setSubmittingState', false)
      console.error(err)
    })
  },

  /**
   * Sets the yup validation schema
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setSchemaObject: ({ commit }, payload) => {
    commit('setSchemaObject', payload)
  },
  
  /**
   * Sets the schema field on the yup validation schema object
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setSchemaObjectValue: ({ commit }, payload) => {
    commit('setSchemaObjectValue', payload)
  },

  /**
   * Sets yup validation schema overrides
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setSchemaOverrides: ({ commit }, payload) => {
    commit('setSchemaOverrides', payload)
  },

  /**
   * Sets available programs on the form
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setAvailablePrograms: ({ commit, getters }, payload) => {
    let programs = []
    let rawPrograms = getters.getPrograms
    let groups = payload.groups ? payload.groups : []

      /** 
       * This will compile an array of programs grouped by the specified criteria.
       * 
       * If there is no criteria to group by, we simply return a flat array of programs to render.
       */
      if (groups.length > 0) {
        groups.forEach(group => {

          /**
           * Program groups need to be checked explicitly since they are nested into a relation object.
           * 
           */
          if (group.field === 'group') {

            programs.push(
              ...rawPrograms.filter(program => {
              
                return program.groups.data.find(item => item.id === group.value)
              })
            )

          } else {

            programs.push(
              ...rawPrograms.filter(program => {
                return program[group.field].toLowerCase().includes(helpers.normalizeFilterValue(group.value.toLowerCase()))
              })
            )

          }

        })

      } else {
        programs.push(
          ...rawPrograms
        )

      }

    commit('setAvailablePrograms', [...programs])
  },
}