export default isValidLocation


function isValidLocation(value) {
    //location values appear to be true or false as a string, an empty string, or a location string - these cases account for that
    if(value === "true") {
        return true
    }
    if(value === "false") {
        return false
    }
    if(typeof value == "string" && value.trim() !== '') { //if the value is a string and it is not empty
        return true
    } else {
        return false
    }
}