<template>
  <div class="fr-form">
    <h2 
      v-if="variant.heading" 
      class="fr-form__heading"
    >
      {{ variant.heading }}
    </h2>
    <p 
      v-if="variant.intro"
      class="fr-form__intro"
    >
      {{ variant.intro }}
    </p>

    <template
      v-for="(step, index) in formSteps"
      :key="`template-${index + 1}`"
    >
      <fr-form-step 
        :step="index + 1" 
        :fields="step"
      />
    </template>
    <fr-form-controls />
    <fr-phone-cta />
    <fr-privacy-text />
    
  </div>
</template>

<script>
import FrFormControls from '@/components/FrFormControls.vue'
import FrFormStep from '@/components/FrFormStep.vue'
import FrPrivacyText from '@/components/FrPrivacyText.vue'
import FrPhoneCta from '@/components/FrPhoneCta.vue'
import { mapActions, mapGetters } from 'vuex'
import validationHelper from '@/helpers/validation'

export default {
  name: 'FormRenderer',

  components: {
    FrFormControls,
    FrFormStep,
    FrPrivacyText,
    FrPhoneCta
  },

  data: () => ({
    //
  }),

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      form: 'getFormConfig',
      variant: 'getFormVariant',
      pageContext: 'getPageContext',
      formSteps: 'getFormSteps',
      currentFormStep: 'getCurrentFormStep',
      formStepsCount: 'getFormStepsCount'
    })
  },

  mounted() {
    this.initValidation()
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions([
      'incrementFormStep',
      'submitForm',
      'setSchemaObject'
    ]),

    initValidation(){
      const { schema, labelLut } = validationHelper.generateStepsSchema(this.formSteps)
      this.setSchemaObject({ schema: schema, lut: labelLut, errors: {} })
      
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
</style>
