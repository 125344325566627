<template>
  <div :class="cssClasses">
    <component
      :is="field.component"
      v-model="submitKey"
      :field="field"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FrFormField",

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true,
    },

    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    },
  },

  emits: ['blur', 'update:modelValue'],

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      submitObject: "getSubmitObject",
    }),

    /**
     * The CSS classes to be applied to the element.
     *
     * This is defined as a computed property so we can dynamically set classes.
     *
     * @returns {Array}
     */
    cssClasses() {
      return ["fr-form-field", ...this.field.meta.classes];
    },

    /**
     * The value of the submit object that the field targets.
     *
     * The `get` and `set` methods are constructed dynamically based
     * on the `submitKey` of the underlying field object.
     *
     * @param {String} val
     *
     * @returns {Array|String|Number}
     */
    submitKey: {
      get() {
        return this.submitObject[this.field.meta.submitKey];
      },
      set(val) {
        if (this.field.meta.submitKey === "location") {
          // Check if the object key's value is empty, null or doesn't exist
          if (typeof val === "object" && Object.keys(val).length === 0) {
            this.updateSubmitObject({
              key: "location",
              value: false,
            });
          } else {
            this.updateSubmitObject({
              key: "location",
              value: true,
            });

            for (const key in val) {
              this.updateSubmitObject({
                key: key,
                value: val[key],
              });
            }
          }
        } else {
          // Handle all other single-value fields
          this.updateSubmitObject({
            key: this.field.meta.submitKey,
            value: val,
          });
        }
      },
    },

    /**
     * The listeners for the form field.
     *
     * This is where the submit object will be updated from for each field.
     *
     * The `input` listener will cover most fields.
     * The `change` listener will cover checkboxes and radio inputs.
     *
     * @returns {Object}
     */
    fieldListeners() {
      return {
        input: (value) => {
          this.submitKey = value;
        },
        change: (value) => {
          this.submitKey = value;
          this.$emit('update:modelValue', value);
        },
      };
    },
  },

  mounted() {
    //
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions({
      updateSubmitObject: "updateSubmitObject",
    }),
  },
};
</script>

<style lang="scss" scoped>
.fr-form-field {
  box-sizing: border-box;
  width: 100%;

  & > div {
    margin-bottom: 10px;
  }
}
.half-width {
  width: 50%;
  padding-right: 5px;

  @media screen and (max-width: 480px) {
    width: 100%;
    padding-right: 0px;
  }
}
</style>
