import axios from "axios";
import validation from "../../helpers/validation";
import state from '@/store/state.js'

/**
 * Checks if a zipcode is a valid United States location.
 * @param {String} val 
 * @returns {Promise}
 */

async function isZip(val) {
  return (async function (val) {

    try {
      let config = {
        method: 'post',
        maxBodyLength: 500,
        url: state.zipValidationUrl,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : JSON.stringify({"zip": val, "account": state.submit.accountId})
      };
    
      let response = await axios(config);
      if(response.status == 200 || response.status >= 500) {
        return true;
      }
      
    } catch(err) {
        if(err.response) {
          validation.setValidationMessage('postal_code', err.response.data.message)
          return false;
        }
        //default fallback
        return false    
    }
  })(val)  
} //end func

export default isZip