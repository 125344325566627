import axios from "axios";
import validation from "../../helpers/validation";
import state from '@/store/state.js'

//add asynchronous call to twilio client on leads pipeline
async function validEmail(val) {
  // min length for an internet traversible email address 'a@b.c'
  if(!!val && val.length < 5){ return false }

  try {
    let config = {
      method: 'post',
      maxBodyLength: 500,
      url: state.emailValidationUrl,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : JSON.stringify({"email": val, "account": state.submit.accountId})
    };
  
    let response = await axios(config);
    if(response.status == 200 || response.status >= 500) {
      return true;
    }
    
  } catch(err) {
      if(err.response) {
        validation.setValidationMessage('email', err.response.data.message)
        return false
      }
      //default fallback
      return false
}
  
 
} //end func

export default validEmail
