<template>
  <div :class="{...cssClasses}">
    <fr-label 
      for-id="fr-programs-filter"
      class=""
    >
      {{ field.meta.label }}
    </fr-label>
    <fr-select
      id="fr-programs-filter"
      v-model="fieldValue"
      ref="selectField"
      :valid="!fieldHasErrors('fr-programs-filter')"
      :required="field.admin.required"
      name="fr-programs-filter"
      :default-option="defaultOption"
      :options="filterOptions"
    />
  </div>
</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrSelect from '@/components/fields/base/FrSelect.vue'
import { mapActions, mapGetters } from 'vuex'
import validation from '@/helpers/validation'

export default {
  name: 'FrProgramsFilter',

  components: {
    FrLabel,
    FrSelect
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      programsFilter: 'getProgramsFilter'
    }),

    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return [
        'fr-programs-filter'
      ]
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {Object} val
     * 
     * @returns {Object}
     */
    fieldValue: {
      get() { return this.programsFilter },
      set(val) { this.setProgramsFilter(val) }
    },
  
    /**
     * The default option to be rendered by the programs filter.
     * 
     * @returns {Array}
     */
    defaultOption() {
      return {
        label: this.field.meta.placeholder ?? 'Please select a Filter',
        value: {},
        selected: true,
        disabled: true,
      }
    },

    /** 
     * The list of options to be rendered by the programs filter. 
     * 
     * The label needs to be extracted to the top level for display purposes.
     * 
     * @returns {Array}
    */
    filterOptions() {
      return [
        ...this.field.meta.filters.map(filter => {
          return {
            label: filter.label,
            value: filter
          }
        })
      ]
    }
  },

  methods: {
    /** 
     * The actions mapped from Vuex.
     */
    ...mapActions({
      setProgramsFilter: 'setProgramsFilter'
    }),

    fieldHasErrors(name) {
      return validation.fieldHasErrors(name)
    },

    focus() {
      this.$refs.selectField.focus();
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>
